import React from "react";
import security_small from "../../assests/icons/security-small.svg";
import "./ResponseModal.scss";
import PropTypes from "prop-types";

const Footer = ({ footerVersion }) => {
  const footerId = `footer__item_container_${footerVersion}`;
  const newShieldIcon =
    "https://hv-camera-web-sg.s3-ap-southeast-1.amazonaws.com/images/gkycShield.png";

  const srcId = footerVersion === "v1" ? security_small : newShieldIcon;

  let imgStyle = {};

  if (srcId !== security_small) {
    imgStyle = {
      height: "11.2px",
      width: "9.3px",
    };
  }

  return (
    <div id={footerId}>
      <img src={srcId} style={imgStyle} />
      <span
        className={
          localStorage.getItem("darkMode") === "yes" ? "dark_font" : ""
        }>
        powered by HyperVerge
      </span>
    </div>
  );
};

export default Footer;

Footer.propTypes = {
  footerVersion: PropTypes.string.isRequired,
};
